import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ blogs }) => {
  console.log(blogs);
  return (
    <div>
      <div className="  lg:w-11/12 grid grid-cols-1 gap-10  md:grid-cols-2 lg:grid-cols-3 px-7 pt-10 pb-24 mx-auto">
        {blogs.map((blog, index) => (
          <Link
            key={index}
            to={`/blog-details/${blog.slug}`}
            className=" card  bg-white shadow-xl"
          >
            <figure>
              <img className="object-cover" src={blog.image} alt="Shoes" />
            </figure>
            <div className="card-body -mb-5">
              {/* <div className="badge badge-secondary">Laravel</div> */}
              <div className=" flex justify-start font-semibold text-lg">
                <p className="text-left">
                  {blog.title }
                </p>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    blog.description.length > 60
                      ? blog.description.slice(0, 70)
                      : blog.description,
                }}
                className="text-left"
              >
                {}
              </p>
            </div>
            <div className="flex justify-between mx-8 my-5">
              <div className="badge badge-lg badge-primary badge-outline">
                {" "}
                {blog?.category?.[0]?.name}
              </div>
              <div className="badge badge-lg badge-primary badge-outline">
                {blog.reading_time} Min Read
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default BlogCard;

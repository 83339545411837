import React from "react";
import axios from "axios";
import { NavLink, useLoaderData } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { featureProjectsApi, featureBlogsApi } from "../../config";
import Share from "../../Components/Share/Share"
import Pill from "../../Components/Pill/Pill";
import BlogCard from "../../Components/BlogCard/BlogCard";
import hljs from "highlight.js";
import "highlight.js/styles/devibeans.css";

const BlogDetails = () => {
  const blogDetails = useLoaderData();
  const [featureBlogs, setfeatureBlogs] = useState([]);

  useEffect(() => {
    axios.get(featureBlogsApi).then((response) => {
      setfeatureBlogs(response.data.data);
    });
  }, []);

  useEffect(() => {
    hljs.highlightAll();
    const snippets = document.getElementsByTagName("pre");
    for (let i = 0; i < snippets.length; i++) {
      const codeElement = snippets[i].getElementsByTagName("code")[0];
      const code = codeElement.innerText;
      // Clean up the language string to remove 'hljs' and get only the language name
      const languageClasses = codeElement.className.split(' ');
      const language = languageClasses
        .find(cls => cls.startsWith('language-'))
        ?.replace('language-', '')
        .replace(/^\w/, c => c.toUpperCase()) || 'Text';

      snippets[i].classList.add("hljs", "rounded-lg", "overflow-hidden");

      // Add language label and copy button
      snippets[i].innerHTML =
        `<div class="flex justify-between items-center px-4 py-2 bg-gray-800 border-b border-gray-700">
          <span class="text-sm text-gray-400">${language}</span>
          <button class="hljs-copy"><img data-tip="Copy to Clipboard" src="../copy.png" class="tooltip w-6"/></button>
        </div>` + snippets[i].innerHTML;

      snippets[i]
        .getElementsByClassName("hljs-copy")[0]
        .addEventListener("click", function () {
          navigator.clipboard.writeText(code);
          this.innerHTML = '<img src="../yes.png" class="w-6"/>';
          let button = this;
          setTimeout(function () {
            button.innerHTML = '<img src="../copy.png" class="w-6"/>';
          }, 2000);
        });
    }
  }, []);

  // console.log("featureBlogsData", featureBlogs);

  // console.log(blogDetails.data);
  const arr = [1, 2, 4];
  return (
    <div className="pt-24">
      <div className="">
        <Pill pillData={blogDetails?.data?.category}></Pill>
        <h1 className="pt-5 pb-1 w-3/5 mx-auto font-semibold text-3xl lg:text-5xl">
          {blogDetails.data?.title}
        </h1>
        <div className="pt-20 w-11/12 lg:w-2/5 justify-around items-center gap-2  flex lg:flex mx-auto p-5 lg:p-5">
          <div className="flex items-center gap-2">
            <div className="avatar">
              <div className=" w-10 rounded-xl">
                <img
                  className=""
                  src={
                    blogDetails.data?.user.image === null
                      ? "/blogImg/avatar.jpg"
                      : blogDetails.data?.user.image
                  }
                  alt="fd"
                />
              </div>
            </div>
            <h1 className="">{blogDetails.data?.user.full_name}</h1>
          </div>
          <div className="flex items-center gap-2">
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
            </div>
            <div className="pl-1">{blogDetails.data?.published_at?.human}</div>
          </div>
          <div className="flex items-center gap-2">
            <div className="">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="pl-1">
              {blogDetails.data?.reading_time} Min Read
            </div>
          </div>
        </div>
        {/* img */}
        <div>
          <img
            src={blogDetails.data?.image}
            className="mx-auto w-11/12 md:w-1/3 lg:w-2/3 rounded-lg  object-cover"
            alt="blog"
          />
          <div className="w-3/4 lg:w-2/4 pt-5 mx-auto text-start">
            <div
              dangerouslySetInnerHTML={{
                __html: blogDetails?.data?.description,
              }}
              className=" text-lg leading-9"
            >
              {}
            </div>
            <Share description={blogDetails.data?.title} />
          </div>
        </div>
        <div className=" pt-10 w-4/5 mx-auto divider"></div>
        <div>
          {/* latest blog section */}
          <h1 className="pt-10 text-4xl font-semibold p-8 lg:text-5xl">
            Similar Blogs
          </h1>
          <BlogCard blogs={featureBlogs}></BlogCard>
        </div>
        <div className="flex justify-center pb-10">
          <NavLink
            to="/blogs"
            className="btn btn-primary shadow-2xl lg:flex items-center px-10"
          >
            Explore All Blogs
            {/* <ArrowRightCircleIcon className="h-6 w-6  ml-2" /> */}
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;

import React, { useEffect, useState } from "react";
import Collaboration from "../../Components/Collaboration/Collaboration";
import FooterAlt from "../../Components/FooterAlt/FooterAlt";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import { projectsApi } from "../../config";
import useTitle from "../../Hooks/useTitle";
const Portfolio = () => {
  useTitle('Portfolio')
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(projectsApi + `?per_page=3&page=${page}` ,  {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET",
        
    });
      const data = await response.json();
      setData(data);
      setProjects((prevItems) => [...prevItems, ...data.data]);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }
    fetchData();

    // if(window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight){
    //   fetchData();
    // }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  // useEffect(() => {
  //   axios.get(projectsApi).then((response) => {
  //     setProjects(response.data.data);
  //   });
  // }, []);

  // console.log(projects);

  return (
    <div className="">
      <section className=" ">
        <h1 className="pt-32 px-20 pl-14 text-4xl font-semibold pb-3 lg:text-6xl">
          Our Accomplishment
        </h1>

        <ProjectCard projects={projects} ></ProjectCard>
      </section>
      {data?.links?.next === null ? (
        <div>
          <Collaboration></Collaboration>
          <FooterAlt></FooterAlt>
        </div>
      ) : (
        <div className=" mt-7 border-gray-300 border-2 border-solid rounded-xl  mx-auto animate-pulse mb-10 w-3/4 lg:w-3/4 p-10">
          <h1 className="h-2 bg-gray-300 rounded-lg w-52 dark:bg-gray-600"></h1>

          <p className="w-48 h-2 mt-6 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-full h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-64 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
          <p className="w-4/5 h-2 mt-4 bg-gray-200 rounded-lg dark:bg-gray-700"></p>
        </div>
      )}
      {/* <Collaboration></Collaboration> */}
    </div>
  );
};

export default Portfolio;

import React, { useEffect, useState } from "react";
import {
  BeakerIcon,
  ArrowRightCircleIcon,
  CommandLineIcon,
} from "@heroicons/react/24/solid";
import { DiLaravel } from "react-icons/fa";
import Collaboration from "../../Components/Collaboration/Collaboration";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import { toSnakeCase } from "../../helper";
import { PROJECT_IMG_URL, featureProjectsApi } from "../../config";
import Button from "../../Components/Button/Button";
import ProjectCard from "../../Components/ProjectCard/ProjectCard";
import useTitle from "../../Hooks/useTitle";
const Home = () => {
  useTitle("Home");
  const [projectData, setProjectData] = useState([]);

  useEffect(() => {
    axios

      .get(featureProjectsApi)
      .then((response) => {
        setProjectData(response.data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log("axios home data", projectData);

  return (
    <div>
      <div className="hero  relative min-h-screen ">
        <div className="hero-content flex-col-reverse mt-5  lg:flex-row-reverse">
          <img
            src="hotpot.webp"
            className="w-10/12 md:w-8/12 lg:w-1/2 pt-10 md:pt-0  rounded-lg"
            alt="fdf"
          />
          <div className="lg:px-10 w-7/12 lg:w-2/4 flex-row justify-start items-start mt-20 ">
            <h1 className=" text-3xl  font-semibold lg:text-6xl text-left md:pt-10 ">
              Fueling Innovation, Powering Digital Transformation
            </h1>
            {/* <p className="py-6  lg:text-left  lg:flex  ">
              Provident cupiditate voluptatem et in. Quaerat fugiat ut assumenda
              excepturi exercitationem quasi. In deleniti eaque aut repudiandae
              et a id nisi. 
            </p> */}
            {/* <button className="   btn btn-primary  lg:flex items-center gap-5 mt-7">
              Get Started 
              <img src="./icons/right-arrow.png" alt="dd" className="w-10"></img>
            </button> */}
            <div className="flex  justify-start gap-5 mt-10">
              <Link to="/portfolio">
                <Button
                  img={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                      />
                    </svg>
                  }
                  text="See Our Work"
                />
              </Link>
            </div>
          </div>
          <div className="h-12  rounded-2xl  animate-bounce border-primary border-solid border  absolute bottom-8 md:bottom-8 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className=" w-7 h-12 text-primary "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* project section */}
      <section className="bg-white  ">
        <div className="py-5 lg:pt-24 text-4xl font-semibold px-10 lg:text-6xl">
          Our Accomplishment
        </div>
        <ProjectCard projects={projectData}></ProjectCard>

        <div className="flex justify-center items-center gap-5 ">
          <Link to="/portfolio">
            <Button
              img={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  />
                </svg>
              }
              text="View Our All Work"
            />
          </Link>
        </div>
      </section>

      {/* our specialty section */}

      <section className="bg-white pb-24">
        <h1 className="py-14 lg:py-28 text-4xl font-semibold p-8 lg:text-6xl">
          Our Services
        </h1>
        <div className="mb-5 grid grid-rows-1 md:grid-cols-2 lg:grid-cols-3 w-max mx-auto  gap-10">
          <div className="card  w-72  bg-gray-100 shadow-xl">
            <div className="card-body">
              <div className="card-actions  flex justify-center">
                <div className=" w-20 h-20 lg:w-24 lg:h-24 bg-white rounded-full shadow-xl relative my-1.5 ">
                  <img
                    src="./icons/erp.png"
                    className="h-12 w-12 lg:w-14 lg:h-14 text-black-500 absolute left-4 top-4 lg:left-5 lg:top-5"
                    alt="dd"
                  ></img>
                  {/* <CommandLineIcon className="h-8 w-8 lg:w-10 lg:h-10 text-black-500 absolute left-4 top-4 lg:left-3 lg:top-3" /> */}
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center pt-5 pb-2">
                Enterprise Software Development
              </h2>
              {/* <p className="text-justifed">
                If a dog chews shoes whose shoes does he choose?
              </p> */}
            </div>
          </div>

          <div className="card w-72 bg-purple-50 shadow-xl">
            <div className="card-body">
              <div className="card-actions  flex justify-center">
                <div className=" w-20 h-20 lg:w-24 lg:h-24 bg-white rounded-full shadow-xl relative my-1.5  ">
                  <img
                    src="./icons/ecom.png"
                    className="h-12 w-12 lg:w-14 lg:h-14 text-black-500 absolute left-4 top-4 lg:left-5 lg:top-5"
                    alt="dd"
                  ></img>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center pt-5 pb-2">
                E-commerce Solutions
              </h2>
              {/* <p className="">
                If a dog chews shoes whose shoes does he choose?
              </p> */}
            </div>
          </div>
          <div className="card w-72 bg-yellow-50 shadow-xl">
            <div className="card-body">
              <div className="card-actions  flex justify-center">
                <div className=" w-20 h-20 lg:w-24 lg:h-24 bg-white rounded-full shadow-xl relative my-1.5 ">
                  <img
                    src="./icons/mobile.png"
                    className="h-12 w-12 lg:w-14 lg:h-14 text-black-500 absolute left-4 top-4 lg:left-5 lg:top-5"
                    alt="dd"
                  ></img>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center pt-5 pb-2">
                Mobile App Development
              </h2>
              {/* <p className="">
                If a dog chews shoes whose shoes does he choose?
              </p> */}
            </div>
          </div>
          <div className="card  w-72  bg-lime-50 shadow-xl">
            <div className="card-body">
              <div className="card-actions  flex justify-center">
                <div className=" w-20 h-20 lg:w-24 lg:h-24 bg-white rounded-full shadow-xl relative my-1.5 ">
                  <img
                    src="./icons/sqa.png"
                    className="h-12 w-12 lg:w-14 lg:h-14 text-black-500 absolute left-4 top-4 lg:left-6 lg:top-4"
                    alt="dd"
                  ></img>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center pt-5 pb-2">
                Software Quality Assurance
              </h2>
              {/* <p className="">
                If a dog chews shoes whose shoes does he choose?
              </p> */}
            </div>
          </div>

          <div className="card w-72 bg-green-50 shadow-xl">
            <div className="card-body">
              <div className="card-actions  flex justify-center">
                <div className="w-20 h-20 lg:w-24 lg:h-24 bg-white rounded-full shadow-xl relative my-1.5 ">
                  <img
                    src="./icons/agile.png"
                    className="h-12 w-12 lg:w-14 lg:h-14 text-black-500 absolute left-4 top-4 lg:left-6 lg:top-5"
                    alt="dd"
                  ></img>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center pt-5 pb-2">
                Agile Development Methodologies
              </h2>
              {/* <p className="">
                If a dog chews shoes whose shoes does he choose?
              </p> */}
            </div>
          </div>
          <div className="card w-72 bg-pink-50 shadow-xl">
            <div className="card-body">
              <div className="card-actions  flex justify-center">
                <div className="w-20 h-20 lg:w-24 lg:h-24 bg-white rounded-full shadow-xl relative my-1.5 ">
                  <img
                    src="./icons/sms.png"
                    className="h-12 w-12 lg:w-14 lg:h-14 text-black-500 absolute left-4 top-4 lg:left-5 lg:top-5"
                    alt="dd"
                  ></img>
                </div>
              </div>
              <h2 className="text-2xl font-semibold text-center pt-5 pb-2">
                Maintenance and Support
              </h2>
              {/* <p className=" text-center">
                If a dog chews shoes whose shoes does he choose?
              </p> */}
            </div>
          </div>
        </div>

        {/* //fdfd */}
      </section>

      <section>
        <div className="mx-2 pt-10 pb-14 lg:-pb-7 lg:-mb-10">
          <h1 className="mx-20 lg:mx-0 -z-10 py-10 lg:my-10 pb-14 lg:pb-14 text-4xl font-semibold p-5 lg:text-6xl">
            Technology We Use
          </h1>
          {/* first row */}
          <div className=" grid grid-cols-2 lg:grid-cols-2 w-8/12 lg:w-6/12 mx-auto ">
            {/* first row first item */}
            <div className="">
              <div className=" flex justify-center">
                <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                  <figure>
                    <img
                      alt=""
                      src="./icons/laravel.png"
                      className="  ease-in transition origin-top delay-300 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                    />
                  </figure>
                  <div
                    className="transition transform  delay-300 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                  >
                    Laravel
                  </div>
                </div>
              </div>
            </div>
            {/* first row second item */}
            <div className="">
              <div className=" flex justify-center">
                <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                  <figure>
                    <img
                      alt=""
                      src="./icons/react.png"
                      className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                    />
                  </figure>
                  <div
                    className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                  >
                    React
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* second row */}
          <div className="lg:relative">
            <div className="lg:relative lg:-top-10 grid grid-cols-3 lg:grid-cols-3 w-full lg:w-9/12 mx-auto ">
              {/* second row first item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/aws.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      AWS
                    </div>
                  </div>
                </div>
              </div>
              {/* second row second item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/php.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      PHP
                    </div>
                  </div>
                </div>
              </div>
              {/* second row third item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/mysql.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      MySQL
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* thrid row */}
          <div className="lg:relative">
            <div className="lg:relative lg:-top-20 grid grid-cols-2 lg:grid-cols-2 w-8/12 lg:w-6/12 mx-auto ">
              {/* third row first item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/django.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      Django
                    </div>
                  </div>
                </div>
              </div>

              {/* third row second item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/vue.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      VUE
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* fourth row */}
          <div className="">
            <div className="lg:relative lg:-top-28 grid grid-cols-3 lg:grid-cols-3 w-full lg:w-9/12 mx-auto ">
              {/* fourth row first item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/docker.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      Docker
                    </div>
                  </div>
                </div>
              </div>
              {/* fourth row second item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/python.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      Python
                    </div>
                  </div>
                </div>
              </div>
              {/* fourth row third item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/postgresql.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      PostgreSql
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* fifth row */}
          <div className="lg:relative lg:bottom-36 grid grid-cols-2 lg:grid-cols-2 w-8/12 lg:w-6/12 mx-auto ">
            {/* fifth row first item */}
            <div className="">
              <div className=" flex justify-center">
                <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                  <figure>
                    <img
                      alt=""
                      src="./icons/express.png"
                      className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                    />
                  </figure>
                  <div
                    className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                  >
                    Express
                  </div>
                </div>
              </div>
            </div>
            {/* fifth row second item */}
            <div className="">
              <div className=" flex justify-center">
                <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                  <figure>
                    <img
                      alt=""
                      src="./icons/flutter.png"
                      className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                    />
                  </figure>
                  <div
                    className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                  >
                    Flutter
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* sixth row */}
          <div className="">
            <div className="lg:relative lg:bottom-44 grid grid-cols-3 lg:grid-cols-3 w-full lg:w-9/12 mx-auto ">
              {/* sixth row first item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/googlecloud.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      GCP
                    </div>
                  </div>
                </div>
              </div>
              {/* sixth row second item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/node.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      Node
                    </div>
                  </div>
                </div>
              </div>
              {/* sixth row third item */}
              <div className="">
                <div className=" flex justify-center">
                  <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                    <figure>
                      <img
                        alt=""
                        src="./icons/mongodb.png"
                        className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                      />
                    </figure>
                    <div
                      className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                    >
                      Mongodb
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* seventh row */}
          <div className="lg:relative lg:bottom-48 grid grid-cols-2 lg:grid-cols-2 w-8/12 lg:w-6/12 mx-auto ">
            {/* seventh row first item */}
            <div className="">
              <div className=" flex justify-center">
                <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                  <figure>
                    <img
                      alt=""
                      src="./icons/codeigniter.png"
                      className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                    />
                  </figure>
                  <div
                    className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                  >
                    Codeigniter
                  </div>
                </div>
              </div>
            </div>
            {/* seventh row second item */}
            <div className="">
              <div className=" flex justify-center">
                <div className=" group w-24 h-24 lg:w-36 lg:h-36 bg-white rounded-full shadow-xl relative">
                  <figure>
                    <img
                      alt=""
                      src="./icons/tailwind.png"
                      className="  ease-in transition origin-top delay-150 duration-300 lg:group-hover:ease-out  lg:group-hover:scale-[0.65] lg:group:hover:-mt-4  w-12 h-12 lg:w-20 lg:h-20 text-black-500 absolute left-6  top-4 lg:left-8 lg:top-8"
                    />
                  </figure>
                  <div
                    className="transition transform  delay-150 duration-300 
                    translate-y-10
                    lg:group-hover:-translate-y-1  lg:opacity-0 group-hover:opacity-100 lg:group-hover:top-24 relative top-7 lg:top-24 flex justify-center items-center text-black text-xs lg:text-base"
                  >
                    Tailwind
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-white pt-28 pb-16">
        <div className="bg-zinc-100 rounded-xl  mx-5 lg:mx-20">
          <h1 className=" -z-10  py-14 lg:py-16 text-4xl font-semibold p-8 lg:text-6xl">
            StackSages at Glance
          </h1>
          <div className=" grid grid-rows-1 md:grid-cols-5 w-max md:w-full mx-auto pb-20 ">
            <div className="card w-11/12 ">
              <div className="card-body">
                <div className="card-actions  flex justify-center">
                  <div className=" w-20 h-20 md:w-16 md:h-16 bg-base-100 rounded-full shadow-xl relative  ">
                    <img
                      src="./icons/project.png"
                      className=" h-12 w-12 md:w-10 md:h-10 text-black-500 absolute left-4 top-4 md:left-2.5 md:top-3"
                      alt="dd"
                    ></img>
                  </div>
                </div>
                <h2 className="text-5xl md:text-3xl lg:text-5xl font-bold text-center pt-5 pb-2 ">
                  10+
                </h2>
                <p className="font-semibold">Project Delivered</p>
              </div>
            </div>
            <div className="card w-11/12 bg-neutral-200 shadow-lg">
              <div className="card-body">
                <div className="card-actions  flex justify-center">
                  <div className=" w-20 h-20 md:w-16 md:h-16 bg-base-100 rounded-full shadow-xl relative  ">
                    <img
                      src="./icons/experience.png"
                      className=" h-12 w-12 md:w-10 md:h-10 text-black-500 absolute left-4 top-4 md:left-2.5 md:top-3"
                      alt="dd"
                    ></img>
                  </div>
                </div>
                <h2 className="text-5xl md:text-3xl lg:text-5xl font-bold text-center pt-5 pb-2 ">
                  5+
                </h2>
                <p className="font-semibold">Years of experience</p>
              </div>
            </div>
            <div className="card w-11/12  ">
              <div className="card-body">
                <div className="card-actions  flex justify-center">
                  <div className=" w-20 h-20 md:w-16 md:h-16 bg-base-100 rounded-full shadow-xl relative  ">
                    <img
                      src="./icons/rate.png"
                      className=" h-12 w-12 md:w-10 md:h-10 text-black-500 absolute left-4 top-4 md:left-2.5 md:top-3"
                      alt="dd"
                    ></img>
                  </div>
                </div>
                <h2 className="text-5xl md:text-3xl lg:text-5xl font-bold text-center pt-5 pb-2 ">
                  20+
                </h2>
                <p className="font-semibold">Satisfied Customers</p>
              </div>
            </div>
            <div className="card w-11/12  bg-neutral-200 shadow-lg">
              <div className="card-body">
                <div className="card-actions  flex justify-center">
                  <div className=" w-20 h-20 md:w-16 md:h-16 bg-base-100 rounded-full shadow-xl relative  ">
                    <img
                      src="./icons/product.png"
                      className=" h-12 w-12 md:w-10 md:h-10 text-black-500 absolute left-4 top-4 md:left-2.5 md:top-3"
                      alt="dd"
                    ></img>
                  </div>
                </div>
                <h2 className="text-5xl md:text-3xl lg:text-5xl font-bold text-center pt-5 pb-2 ">
                  3+
                </h2>
                <p className="font-semibold">In house Products</p>
              </div>
            </div>
            <div className="card w-11/12  ">
              <div className="card-body">
                <div className="card-actions  flex justify-center">
                  <div className=" w-20 h-20 md:w-16 md:h-16 bg-base-100 rounded-full shadow-xl relative  ">
                    <img
                      src="./icons/team.png"
                      className=" h-12 w-12 md:w-10 md:h-10 text-black-500 absolute left-4 top-4 md:left-2.5 md:top-3"
                      alt="dd"
                    ></img>
                  </div>
                </div>
                <h2 className="text-5xl md:text-3xl lg:text-5xl font-bold text-center pt-5 pb-2 ">
                  5+
                </h2>
                <p className="font-semibold">Team Members</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Collaboration></Collaboration>
    </div>
  );
};

export default Home;

import React from 'react';
import { Link } from 'react-router-dom';
import { toSnakeCase } from "../../helper";
import parse from 'html-react-parser';

const ProjectCard = ( { projects }) => {
    return (
        <div className="pb-5">
          {" "}
          {projects.map((project, index) => (
            <div
              key={index}
              className=" border border-white border-solid  rounded-xl w-3/4 md:w-3/4 bg-zinc-50 shadow-xl hover:border hover:border-primary my-10 lg:my-20 mx-auto"
            >
              <Link
                to={`/project-details/${project.slug}`}
                className="flex flex-col  md:flex-row md:gap-20 "
              >
                <div className="md:w-11/12">
                  
                    <img
                      className="bg-blue-50    rounded-tl-xl rounded-tr-xl lg:rounded-tr-none lg:rounded-l-xl "
                      // src="./default-project-img.png"
                      src={
                        project.image === null
                          ? "./default-project-img.png"
                          : project.image
                        // project.image
                      }
                      alt="Shoes"
                    />
                  
                </div>
                <div className=" mt-5  md:pr-20 lg:pr-28 flex-col flex justify-around pt-5  ">
                  <div className="">
                    <h2 className="text-2xl  font-semibold md:text-2xl lg:text-3xl">
                      {project.title}
                    </h2>
                    <div  className="text-justify md:hidden lg:block pt-6 px-9 lg:px-0">
                      {  project.description.length > 150
                        ? parse( project.description.slice(0, 70) + "..." )
                        : project.description}{" "}
                    </div>
                  </div>
                  <div className="flex justify-center pt-7 pb-8 lg:pt-0  p-1 lg:p-3 gap-4 lg:gap-4">
                    {project.technologies.map((item, index) => (
                      <figure key={index}>
                        <img
                          src={`icons/${toSnakeCase(item.name)}.png`}
                          className="w-6 h-6 lg:w-8 lg:h-8"
                          srcSet=""
                          alt=""
                        />
                      </figure>
                    ))}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
    );
};

export default ProjectCard;
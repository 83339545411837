export const DOMAIN = 'https://server.stacksages.com'
// export const DOMAIN = 'http://127.0.0.1:8000'
export const PREFIX = '/api'



// export const PROJECT_IMG_URL = DOMAIN+'images/projects/'


export const HOST = DOMAIN+PREFIX
export const blogApi = HOST+'/blogs'
export const projectsApi = HOST+'/projects'
export const featureProjectsApi = HOST+ '/projects?filter=feature_projects'
export const featureBlogsApi = HOST+ '/blogs?filter=feature_blogs&count=3'


export const sendQuery = HOST+ '/contact-us/store'
export const sendProposal = HOST+ '/project-proposal/store'
import React from "react";
import useTitle from "../../Hooks/useTitle";





const About = () => {
  useTitle('About')
  return (
    <div className="">
      <div className="   ">
        <div className="lg:min-h-screen ">
          <div className=" text-center">
            <div className="flex flex-col justify-center items-center">
              <div  className=" mt-20 pt-20">

              <h1 className=" text-4xl mx-10 lg:text-6xl font-bold my-6">Digitalize Visions into Reality</h1>
              {/* <button className="btn btn-primary my-4">Get Started</button> */}
              </div>
              {/* <p className="py-6">
                Provident cupiditate voluptatem et in. Quaerat fugiat ut
                assumenda excepturi exercitationem quasi. In deleniti eaque aut
                repudiandae et a id nisi.
              </p> */}
              <img src="/about-main.png" className=" rounded-lg pt-12 pb-10 " alt="about"/>
            </div>
          </div>
        </div>

        <div className="hero pb-5 lg:pb-10 lg:pr-10 lg:pl-10 lg:p-0 min-h-screen bg-white">
          <div className="hero-content flex-col mt-5 pt-5 gap-16 lg:flex-row-reverse">
            <div className="w=2/3 lg:w-1/3 flex-row">
              <h1 className="py-4 text-4xl  font-bold lg:text-5xl lg:text-left pt-14 ">
                Who We Are
              </h1>
              <div className="mx-8 lg:mx-0">

              <p className="py-2  lg:text-left  lg:flex leading-7 ">
              Stacksages is a web software development company specializing in creating feature-rich,  dynamic software solutions that optimize business problems. </p>
              <p className="py-2  lg:text-left  lg:flex leading-7  "> With a flexible approach, we work with any tech stack to cater to the unique requirements and preferences of our clients, ensuring a customized and satisfying experience. </p>
              </div>
              
              {/* <button className="btn btn-primary lg:flex items-center mt-4">
                Contact Us
              </button> */}
            </div>
            <img src="/about-2.jpg" className="mx-2 lg:w-1/2 rounded-lg " alt="about"/>
          </div>
        </div>
      </div>
      {/* <h1 className="pt-5 pb-10 text-4xl font-semibold p-8 lg:text-5xl">
        Amazing Team
      </h1>

      <div className="w-3/5 gap-5 mx-auto grid grid-cols-1 lg:grid lg:grid-cols-3 bg-white">
        {arr.map((arra, index) => (
          <div key={index} className="card card bg-base-100 shadow-xl">
            <figure>
              <img src="/avatar.png" className="" alt="Movie" />
            </figure>
            <div className="card-body">
              <h2 className="card-title">New movie is released!</h2>
              <p>Click the button to watch on Jetflix app.</p>
              <div className="card-actions justify-end"></div>
            </div>
          </div>
        ))}
      </div> */}

      <section>
        <div className=" pb-5 lg:pb-10 lg:pr-10 lg:pl-10 lg:p-0  bg-white">
          <div className="hero-content flex-col gap-10  pt-5 lg:flex-row">
            <div className="w=2/3 lg:w-1/3 flex-row">
              <h1 className=" text-4xl py-5  font-bold lg:text-5xl lg:text-left  ">
                Our Ambition
              </h1>
              <div className="mx-8 lg:mx-0">
                
              <p className="py-2  lg:text-left  lg:flex leading-7 ">
              Stacksages is a web software development company specializing in creating feature-rich,  dynamic software solutions that optimize business problems. </p>
              <p className="py-2  lg:text-left  lg:flex leading-7  "> With a flexible approach, we work with any tech stack to cater to the unique requirements and preferences of our clients, ensuring a customized and satisfying experience. </p>
              </div>
              {/* <button className="btn btn-primary lg:flex items-center mt-4">
                Contact Us
              </button> */}
            </div>
            <img src="/our-ambition-2.jpg" className="lg:w-1/2 rounded-lg" alt="" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;

import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { blogApi } from "../../config";
import Collaboration from "../../Components/Collaboration/Collaboration";
import FooterAlt from "../../Components/FooterAlt/FooterAlt";
import BlogCard from "../../Components/BlogCard/BlogCard";
import useTitle from "../../Hooks/useTitle";

const Blog = () => {
  useTitle('Blog')
  // const arr = [1, 2, 3];
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);

    try {
    

      const response = await fetch(blogApi + `?per_page=6&page=${page}`,  {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "GET",
        
    });


       const data = await response.json();
       setData(data);
       setBlogs((prevItems) => [...prevItems, ...data.data]);
       setPage((prevPage) => prevPage + 1);
       
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(blogs) 
  useEffect(() => {
    fetchData();
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isLoading
    ) {
      return;
    }
    fetchData();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  //  console.log(data)
  return (
    <div className="">
      <h1 className="pt-32 text-4xl font-semibold pb-6 lg:text-6xl">
        Our Blogss
      </h1>
      <BlogCard blogs={blogs}></BlogCard>
      {data?.links?.next  === null ? (
        <div>
          <Collaboration></Collaboration>
          <FooterAlt></FooterAlt>
        </div>
      ) : (
        <div
          className="inline-block mb-10 h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      )}
    </div>
  );
};

export default Blog;
